import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


// 解决vue-router在3.0版本以上重复点击的报错问题
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};
const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import( '../views/Index.vue'),
        meta: {
            title: '首页'
        }
    },
    {
        path: '/Layout',
        name: 'Layout',
        component: () => import( '../views/Layout.vue'),
        meta: {},
        children: [
            {
                path: '/PatientManagement',
                name: 'PatientManagement',
                component: () => import( '../views/PatientManagement.vue'),
                meta: {
                    title: '患者管理'
                },
            },

            {
                path: '/AddPatient',
                name: 'AddPatient',
                component: () => import( '../views/AddPatient.vue'),
                meta: {
                    title: '添加患者',
                    parent: '/PatientManagement'
                },
								beforeEnter:(to, from, next) => {
									if(to.query.id && to.query.id !== ''){
										to.meta.title = '编辑患者'
									}else{
										to.meta.title = '添加患者'
									}
									next()
								}
            },
            {
                path: '/Assess',
                name: 'Assess',
                component: () => import( '../views/Assess.vue'),
                meta: {
                    title: '评估测试'
                },
            },
            {
                path: '/AssessDetail',
                name: 'AssessDetail',
                component: () => import( '../views/AssessDetail.vue'),
                meta: {
                    title: '评估测试',
                    subtitle: '测评量表',
                    parent: '/Assess'
                },

            },
            {
                path: '/ReportPage',
                name: 'ReportPage',
                component: () => import( '../views/ReportPage.vue'),
                meta: {
                    title: '评估测试',
                    subtitle: '测评报告',
                }
            },
            {
                path: '/SystemSetting',
                name: 'SystemSetting',
                component: () => import( '../views/SystemSetting.vue'),
                meta: {
                    title: '系统设置'
                }
            },
            {
                path: '/SettingConfig',
                name: 'SettingConfig',
                component: () => import( '../views/SettingConfig.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '参数设置',
                    parent: '/SystemSetting'
                }
            },
            {
                path: '/SettingUser',
                name: 'SettingUser',
                component: () => import( '../views/SettingUser.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '系统用户',
                    parent: '/SystemSetting'
                }
            },
            {
                path: '/InspectionPersonnel',
                name: 'InspectionPersonnel',
                component: () => import( '../views/InspectionPersonnel.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '检测人员',
                    parent: '/SystemSetting'
                }
            },
            {
                path: '/SettingUserDetail',
                name: 'SettingUserDetail',
                component: () => import( '../views/SettingUserDetail.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '用户详情',
                    parent: '/SystemSetting'
                },
                beforeEnter:(to, from, next) => {
                    if(to.query.id && to.query.id !== ''){
                        to.meta.subtitle = '编辑用户'
                    }else{
                        to.meta.subtitle = '用户详情'
                    }
                    next()
                }
            },
            {
                path: '/SettingWard',
                name: 'SettingWard',
                component: () => import( '../views/SettingWard.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '患者来源设置',
                    parent: '/SystemSetting'
                }
            }, {
                path: '/SettingRole',
                name: 'SettingRole',
                component: () => import( '../views/SettingRole.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '角色设置',
                    parent: '/SystemSetting'
                }
            },
            {
                path: '/SettingDoctorSay',
                name: 'SettingDoctorSay',
                component: () => import( '../views/SettingDoctorSay.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '医生建议',
                    parent: '/SystemSetting'
                }
            },

            {
                path: '/SettingDoctorSayInfo',
                name: 'SettingDoctorSayInfo',
                component: () => import( '../views/SettingDoctorSayInfo.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '模板管理',
                    parent: '/SystemSetting'
                }
            },
            {
                path: '/SettingScale',
                name: 'SettingScale',
                component: () => import( '../views/SettingScale.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '量表帮助',
                    parent: '/SystemSetting'
                }
            },
            {
                path: '/SettingScaleInfo',
                name: 'SettingScaleInfo',
                component: () => import( '../views/SettingScaleInfo.vue'),
                meta: {
                    title: '量表帮助',
                    parent: '/SystemSetting'
                }
            },
            {
                path: '/messagePage',
                name: 'messagePage',
                component: () => import( '../views/messagePage.vue'),
                meta: {
                    title: '消息中心',
                }
            },
           {
                path: '/SettingExplain',
                name: 'SettingExplain',
                component: () => import( '../views/SettingExplain.vue'),
                meta: {
                    title: '系统设置',
                    subtitle: '系统说明',
                    parent: '/SystemSetting'
                }
            },
            {
                path: '/ReportCenter',
                name: 'ReportCenter',
                component: () => import( '../views/ReportCenter.vue'),
                meta: {
                    title: '报告中心'
                }
            }, {
                path: '/TestReport',
                name: 'TestReport',
                component: () => import( '../views/TestReport.vue'),
                meta: {
                    title: '报告中心',
                    subtitle: '测评报告',
                    parent: '/ReportCenter'
                },

            },


            {
                path: '/TerminalAnswer',
                name: 'TerminalAnswer',
                component: () => import( '../views/TerminalAnswer'),
                meta: {
                    title: '答题',
                }
            },
            {
                path: '/TerminalAnswerScreen',
                name: 'TerminalAnswerScreen',
                component: () => import( '../views/TerminalAnswerScreen'),
                meta: {
                    title: '答题副屏',
                }
            },
            {
                path: '/TerminalEnd',
                name: 'TerminalEnd',
                component: () => import( '../views/TerminalEnd'),
                meta: {
                    title: '结束',
                }
            },

        ]
    },
    {
        path: '/Test',
        name: 'Test',
        component: () => import( '../views/Test.vue'),
        meta: {
            title: 'test'
        }
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import( '../views/Login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/hukou',
        name: 'hukou',
        component: () => import( '../views/hukou.vue'),
        meta: {
            title: '选择版本'
        }
    },

]



const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router
